import React from 'react';
import { Identity } from '@wix/editor-elements-types';
import { IBgImageStripProps } from '../BgImageStrip.types';
import BaseBgImageStrip from './skinComps/BaseBgImageStrip/BaseBgImageStrip';
import DoubleBorderBgImageStrip from './skinComps/DoubleBorderBgImageStrip/DoubleBorderBgImageStrip';

type BgImageStripSkin = IBgImageStripProps['skin'];

export const SKIN_NAMES: Identity<BgImageStripSkin> = {
  BevelScreenSkin: 'BevelScreenSkin',
  BgImageStripSkin: 'BgImageStripSkin',
  DefaultScreenSkin: 'DefaultScreenSkin',
  IronScreenSkin: 'IronScreenSkin',
  DoubleBorderScreenSkin: 'DoubleBorderScreenSkin',
} as const;

export const BgImageStripSkinMap: {
  [P in BgImageStripSkin]: React.FC<IBgImageStripProps>;
} = {
  BevelScreenSkin: BaseBgImageStrip,
  BgImageStripSkin: BaseBgImageStrip,
  DefaultScreenSkin: BaseBgImageStrip,
  IronScreenSkin: BaseBgImageStrip,
  DoubleBorderScreenSkin: DoubleBorderBgImageStrip,
};
